<template>
  <div
    v-if="isDataLoaded"
    class="px-4 px-lg-10 py-8 staff-list"
  >
    <!-- Header -->
    <div class="d-flex flex-column flex-sm-row justify-end mb-sm-4">
      <!-- Roles Table -->
      <v-dialog
        v-if="isAcademy"
        v-model="showRolesDialog"
        max-width="700"
        class="staff-list__dialog-roles"
      >
        <template #activator="{ on, attrs }">
          <v-row
            align="start"
            v-bind="attrs"
            class="ma-2"
            v-on="on"
          >
            <v-icon small color="primary" class="mt-1">
              remove_red_eye
            </v-icon>
            <span class="primary--text ml-2" v-text="$t('user.roles.seeInfo')" />
          </v-row>
        </template>
        <v-card>
          <v-card-title class="d-flex justify-end">
            <v-list-item-action class="ma-0">
              <v-btn icon color="black" @click="showRolesDialog = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-card-title>
          <v-simple-table class="px-6">
            <template #default>
              <thead>
                <tr>
                  <th />
                  <th class="text-center">
                    <div class="d-flex align-center staff-list__dialog-roles__table-header">
                      <span class="w-100 text-center" v-text="$t('user.roles.manager')" />
                    </div>
                  </th>
                  <th class="text-center">
                    <div class="d-flex align-center staff-list__dialog-roles__table-header">
                      <span class="w-100 text-center" v-text="$t('user.roles.doc')" />
                    </div>
                  </th>
                  <th class="text-center">
                    <div class="d-flex align-center staff-list__dialog-roles__table-header">
                      <span class="w-100 text-center" v-text="$t('user.roles.coach')" />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="role in roles"
                  :key="role.name"
                >
                  <td class="pl-0">
                    <span class="font-weight-medium" v-text="$t(`user.roles.table.${role.name}`)" />
                  </td>
                  <td>
                    <v-row align="center" justify="center">
                      <span :class="role.manager && 'primary--text'" v-text="role.manager ? '✔' : '❌'" />
                    </v-row>
                  </td>
                  <td>
                    <v-row align="center" justify="center">
                      <span :class="role.doc && 'primary--text'" v-text="role.doc ? '✔' : '❌'" />
                    </v-row>
                  </td>
                  <td>
                    <v-row align="center" justify="center">
                      <span :class="role.coach && 'primary--text'" v-text="role.coach ? '✔' : '❌'" />
                    </v-row>
                  </td>
                </tr>
                <v-divider />
              </tbody>
            </template>
          </v-simple-table>
          <v-footer
            color="white"
            class="pl-6"
            height="80"
          >
            <span class="caption" v-text="$t('user.roles.footnote')" />
          </v-footer>
        </v-card>
      </v-dialog>
      <!-- Search -->
      <span>
        <v-text-field
          v-if="formatStaff.length"
          v-model="search"
          :label="$t('common.search')"
          prepend-inner-icon="search"
          tag="span"
          outlined
          dense
          hide-details
        />
      </span>

      <export-to-csv
        v-if="formatStaff.length"
        :file-name-preffix="`${project.name} - Staff`"
        action-name="academy/getStaffCsv"
        :action-params="{ organizationId, projectId }"
        :button-text="$t('common.exportToCsv')"
      />

      <v-btn v-if="projectId" class="ml-3" color="primary" style="min-height: 40px;" outlined @click="showAddStaffDialog = true">
        {{ $t('common.sendInvitation') }}
        <v-icon right>
          send
        </v-icon>
      </v-btn>
    </div>
    <v-data-table v-if="formatStaff.length"
                  :headers="headers"
                  :items="formatStaff"
                  :sort-by="['firstName']"
                  :sort-desc="false"
                  :search="search"
                  dense
    >
      <template #[`item.avatar`]="{ item }">
        <v-avatar size="36">
          <img v-if="item.avatar" alt="Avatar" :src="item.avatar">
          <v-icon v-else color="primary" v-text="'person'" />
        </v-avatar>
      </template>
      <template #[`item.email`]="{ item }">
        <copy-text :text="item.email" />
      </template>
      <template #[`item.role`]="{ item }">
        <span class="text-capitalize" v-text="$t(`user.roles.${item.role}`)" />
      </template>
      <template #[`item.teams`]="{ item }">
        <div class="d-flex align-center justify-space-between">
          <div v-if="isAcademy" class="d-flex align-center">
            <multiple-labels :labels="item.teams" />
            <!-- TODO: -->
            <!-- in StaffListTable.vue was code TODO, found code of StaffListTable.vue in P.R of task OW-3872 -->
          </div>
          <v-icon v-if="item.role === 'coach'" color="primary" @click="selectedStaff = item; showUpdateTeamsDialog = true" v-text="'create'" />
        </div>
      </template>
    </v-data-table>
    <empty-list v-else :content="$t('organization.sections.people')" />

    <!-- Dialogs -->
    <add-staff-dialog
      v-if="showAddStaffDialog"
      :organization-id="organizationId"
      :project-id="projectId"
      @close-dialog="showAddStaffDialog = false"
    />
    <edit-staff-dialog
      v-if="showEditStaffDialog"
      :organization-id="organizationId"
      :project-id="projectId"
      :item="selectedStaff"
      @close-dialog="showEditStaffDialog = false"
    />
    <update-teams-dialog
      v-if="showUpdateTeamsDialog"
      :organization-id="organizationId" :project-id="projectId" :item="selectedStaff" @closeDialog="showUpdateTeamsDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import roleTable from '@/enums/rolesTable'

export default {
  name: 'StaffList',
  components: {
    EmptyList: () => import('@/components/EmptyList'),
    ExportToCsv: () => import('@/components/ExportToCsv'),
    AddStaffDialog: () => import('@/modules/projectUsers/AddStaffDialog'),
    EditStaffDialog: () => import('@/modules/projectUsers/EditStaffDialog'),
    UpdateTeamsDialog: () => import('@/modules/projectUsers/UpdateTeamsDialog'),
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isDataLoaded: false,
      showAddStaffDialog: false,
      showEditStaffDialog: false,
      showRolesDialog: false,
      showUpdateTeamsDialog: false,
      roles: roleTable,
      selectedStaff: null,
      search: null,
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ teams: 'academy/teams' }),
    ...mapGetters({ isAcademy: 'project/isAcademy' }),
    ...mapGetters({ staffList: 'projectUsers/staff' }),
    headers() {
      return [
        { text: this.$t('user.fields.avatar'), value: 'avatar' },
        { text: this.$t('user.fields.firstName'), value: 'firstName', cellClass: 'text-capitalize' },
        { text: this.$t('user.fields.lastName'), value: 'lastName', cellClass: 'text-capitalize' },
        { text: this.$t('user.fields.email'), value: 'email' },
        { text: this.$t('user.fields.job'), value: 'role' },
        ...(this.isAcademy ? [{ text: this.$t('common.team'), value: 'teams' }] : []),
      ]
    },
    formatStaff({ staffList, formatTeams }) {
      return staffList.map(staffItem => ({
        ...staffItem,
        teams: formatTeams(staffItem),
      }))
    },
  },
  async created() {
    const { organizationId, projectId } = this
    this.$store.commit('loader/show')
    await this.$store.dispatch('projectUsers/bindManagers', { organizationId, projectId })
    await this.$store.dispatch('projectUsers/bindSubmanagers', { organizationId, projectId })
    await this.$store.dispatch('projectUsers/bindCoaches', { organizationId, projectId })
    await this.$store.dispatch('projectUsers/bindMatchEditors', { organizationId, projectId })
    this.isDataLoaded = true
    this.$store.commit('loader/hide')
  },
  methods: {
    formatTeams(staffItem) {
      const { organizationId, projectId, teams } = this
      return staffItem.roles.byProperty[organizationId].byProject[projectId]?.byTeam ? Object.keys(
        staffItem.roles.byProperty[organizationId].byProject[projectId]?.byTeam,
      ).map(team => teams.find(t => t.id === team)?.fullTeamName) : []
    },
    editStaff(staffItem) {
      this.selectedStaff = staffItem
      this.showEditStaffDialog = true
    },
    removeStaff(staffItem) {
      const { organizationId, projectId } = this
      this.runAsync(async () => {
        await this.$store.dispatch('projectUsers/removeStaffer', { organizationId, projectId, userId: staffItem.id, role: staffItem.role })
      })
    },
  },
}
</script>

<style lang="scss">

.staff-list {
  &__dialog-roles {
    &__table-header {
      height: 2rem;
      background-color: #2accccaa;
      border-radius: 4px;
    }
  }

  .v-btn--outlined {
    border: none !important;
  }

  .v-avatar img {
    width: 100%;
    object-fit: cover;
  }

  .v-data-table {
    tr {
      td {
        padding: 8px 16px 8px 16px !important;
      }
    }
  }
}
</style>
