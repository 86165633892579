export default [
  {
    name: 'generic',
    manager: true,
    doc: true,
    coach: false,
  },
  {
    name: 'category',
    manager: true,
    doc: true,
    coach: false,
  },
  {
    name: 'form',
    manager: true,
    doc: true,
    coach: false,
  },
  {
    name: 'waiver',
    manager: true,
    doc: true,
    coach: false,
  },
  {
    name: 'enrollment',
    manager: true,
    doc: true,
    coach: false,
  },
  {
    name: 'payment',
    manager: true,
    doc: false,
    coach: false,
  },
  {
    name: 'history',
    manager: true,
    doc: false,
    coach: false,
  },
  {
    name: 'admission',
    manager: true,
    doc: true,
    coach: false,
  },
  {
    name: 'communication',
    manager: true,
    doc: true,
    coach: true,
  },
  {
    name: 'notification',
    manager: true,
    doc: true,
    coach: true,
  },
  {
    name: 'pupil',
    manager: true,
    doc: true,
    coach: true,
  },
  {
    name: 'staff',
    manager: true,
    doc: true,
    coach: true,
  },
  // {
  //   name: 'group',
  //   manager: true,
  //   doc: true,
  //   coach: true,
  // },
  {
    name: 'calendar',
    manager: true,
    doc: true,
    coach: true,
  },
  // {
  //   name: 'activity',
  //   manager: true,
  //   doc: true,
  //   coach: true,
  // },
  {
    name: 'criteria',
    manager: true,
    doc: true,
    coach: false,
  },
  {
    name: 'assessment',
    manager: true,
    doc: true,
    coach: true,
  },
  {
    name: 'publication',
    manager: true,
    doc: true,
    coach: true,
  },
  {
    name: 'log',
    manager: true,
    doc: true,
    coach: false,
  },
]